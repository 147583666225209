@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'BebasNeuePro-BoldItalic';
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: url('../assets/fonts/Bebas_Neue_Pro_Bold_Italic.otf') format('opentype');
}

@font-face {
  font-family: 'GolosText-Regular';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts/GolosText-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'GolosText-Medium';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../assets/fonts/GolosText-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'GolosText-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../assets/fonts/GolosText-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'GolosText-Bold';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../assets/fonts/GolosText-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-Thin';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('../assets/fonts/Raleway-VariableFont_wght.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: "GolosText-Regular", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 14.768px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[id] {
  scroll-margin-top: 110px;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-bebas {
  font-family: 'BebasNeuePro-BoldItalic', GolosText-Medium;
  font-style: italic;
}

.font-golos {
  font-family: 'GolosText-Regular';
}

.font-golos-medium {
  font-family: 'GolosText-Medium';
}

.font-golos-semibold {
  font-family: 'GolosText-SemiBold';
}

.font-golos-bold {
  font-family: 'GolosText-Bold';
}

.font-raleway {
  font-family: 'Raleway-Thin';
}

input:-internal-autofill-selected {
  background-color: inherit !important;
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: inherit !important;
}

.menu-close-padding-custom .p-sidebar-header {
  padding-bottom: 0.67rem;
}

.timeline-top::after {
  content: "";
  position: absolute;
  top: 0;
  height: 35%;
  border-left: 2px dashed #fff;
  width: 1px;
  z-index: 0;
}

.timeline-top1::after {
  content: "";
  position: absolute;
  top: -17px;
  height: 80%;
  border-left: 2px dashed #fff;
  width: 1px;
  z-index: 0;
}

.timeline-top2::after {
  content: "";
  position: absolute;
  top: -17px;
  height: 80%;
  border-left: 2px dashed #fff;
  width: 1px;
  z-index: 0;
}

.timeline-bottom::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 22%;
  border-left: 2px dashed #fff;
  width: 1px;
  z-index: 0;
}

.timeline-left .p-image::after {
  content: "";
  position: absolute;
  left: 0;
  height: 1px;
  border-bottom: 1px solid #342566;
  width: 50%;
  z-index: 0;
}

.timeline-right .p-image::after {
  content: "";
  position: absolute;
  right: 0;
  height: 1px;
  border-bottom: 1px solid #342566;
  width: 50%;
  z-index: 0;
}

.c-pointer {
  cursor: pointer;
}

.p-button:focus {
  box-shadow: none;
}

.hover\:bg-Orchid:hover {
  border-color: inherit !important;
  background-color: rgb(195 128 184 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-white:hover {
  border-color: transparent !important;
  background-color: rgb(221 221 224 / var(--tw-bg-opacity)) !important;
  color: inherit !important;
}

.player-wrapper-react-player {
  position: relative;
  padding-top: 56.25%;
}

.player-wrapper-react-player .react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.cover-video video {
  object-fit: cover;
}

.custom-carousel .p-carousel-indicators {
  justify-content: flex-start;
  position: relative;
  top: -80px;
  left: 50px;
  width: 50%;
}

.custom-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 1rem;
}

.custom-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #fff;
  border-radius: 100%;
  height: 0.55rem;
  width: 0.55rem;
}

.custom-carousel.pagination-indicators-gray .p-carousel-indicators .p-carousel-indicator button {
  background-color: #F3F1FC;
}

.custom-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #df9cd4;
}

.p-dropdown-label {
  font-size: inherit;
}

.p-dropdown-panel .p-dropdown-filter.p-inputtext {
  border-color: #dedede;
  border-radius: 8px;
}

.star-full.selected {
  animation: star-glow 0.5s linear;
}

.custom-dropdown-panel .p-multiselect-items {
  padding-top: 0.45rem;
}

.custom-dropdown-panel .p-dropdown-items .p-dropdown-item-group,
.custom-dropdown-panel .p-multiselect-items .p-multiselect-item-group {
  padding: 0.35rem 1.25rem;
}

.custom-dropdown .p-dropdown-label,
.custom-dropdown .p-dropdown-label.p-placeholder {
  color: inherit;
}

.custom-dropdown.padding-reset .p-dropdown-label {
  padding-top: 0;
  padding-bottom: 0;
}

.container_table .form-check-input {
  border-color: #dedede;
  cursor: pointer;
  height: 1.40em !important;
  width: 1.40em !important;
}

.check-input-custom {
  border-color: #dedede;
  cursor: inherit;
  height: 1rem !important;
  width: 1rem !important;
}

.container_table .form-check-input[type=checkbox]:indeterminate,
.check-input-custom[type=checkbox]:indeterminate {
  color: #7e73d6;
  background-color: #7e73d6;
}

.container_table .form-check-input:checked,
.check-input-custom:checked {
  color: #7e73d6;
  background-color: #7e73d6;
}

.container_table select {
  background: none;
  padding-top: 0;
  padding-bottom: 0;
}

.disable-autofill-white:-webkit-autofill {
  background-color: white !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  color: inherit !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  scrollbar-width: none;
}

.slick-gap .slick-list>.slick-track {
  gap: 1.5rem;
}

.slider-flex-height .slick-track {
  display: flex;
  align-items: stretch;
}

.slider-flex-height .slick-slide {
  flex: 1;
  height: inherit !important;
}

.slider-flex-height .slick-slide>div {
  flex: 1;
  height: 100% !important;
}

.player-wrapper-responsive {
  border-radius: inherit;
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  overflow: hidden;
}

.player-wrapper-responsive .player-wrapper-video,
.player-thumbnail-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  border-radius: inherit;
}

.player-wrapper-responsive .player-wrapper-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.player-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.thumbnail-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.65);
  border-radius: inherit;
  z-index: 1;
}

.player-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.no_highlights {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Hyperlink {
  text-decoration: underline;
  color: #0000ff;
}

@keyframes star-glow {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@layer components {
  .truncated {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .break-word {
    word-wrap: break-word;
    word-break: break-word;
  }

  .aspect-ratio-full {
    aspect-ratio: 16 / 9;
  }

  .no-tap-highlight {
    -webkit-tap-highlight-color: transparent;
  }
}

@layer utilities {
  .font-extralight {
    font-weight: 200;
  }

  .font-semibold {
    font-weight: 600;
  }

  .font-extrabold {
    font-weight: 800;
  }
}

@media screen and (max-width: 767px) {
  .timeline-bottom::after {
    display: none;
  }

  .timeline-top::after {
    display: none;
  }
}

/**/
html {
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}